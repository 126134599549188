.LoginWrapper {
  background: url("../../public/assets/hikal.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.container {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(100%) blur(5px);
}

:root {
  --secondary-rgb: 2, 35, 52;
  --primary: #00af6b;
}

.noise-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.noise-glass {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  pointer-events: none;
}

.bouncing-blobs {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bouncing-blob {
  width: calc(42%);
  aspect-ratio: 1;
  border-radius: 50%;
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform-origin: left top;
}

.bouncing-blob--1 {
  background: rgba(var(--secondary-rgb), 0.1);
}
.bouncing-blob--2 {
  background: var(--primary);
}
.bouncing-blob--3 {
  background: rgba(var(--secondary-rgb), 0.5);
}
.bouncing-blob--4 {
  background: #ffffff;
  z-index: 2;
  width: calc(25%);
}
